import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import pick from 'lodash.pick';
import { md5 } from 'js-md5';

import { GraphView2 } from '../../components/GraphView2';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  filterGraph,
  getDataSource,
  getDefaultColumns,
  getEdgeMappingColumns,
  getEdges,
  getSelectedTypes,
} from '../../utils/graphUtils';

import {
  createNodeAsync,
  deleteNodeAsync,
  getNodesMetadataAsync,
  getNodeOptionsAsync,
  getRelationshipsMetadataAsync,
  selectGraphLegend,
  selectGraphSize,
  selectNodesMetadata,
  selectNodeOptions,
  selectRelationshipsMetadata,
  runGraphQuery,
  selectGraphs,
} from '../graph/graphSlice';
import icons from '../ontology/icons';

export function Graph2() {

  const initialValue = {
    fetchType: 'search',
    graph: { nodes: [], relatonships: [], legend: {} },
    layout: 'graph',
    tableView: 'nodes',
    nodeStyle: 'color',
  };

  const [value, setValue] = useState(initialValue);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const correlationIdRef = useRef(null);

  // const graphLegend = useSelector(selectGraphLegend);
  // const graphSize = useSelector(selectGraphSize);
  const graphs = useSelector(selectGraphs);
  const nodeOptions = useSelector(selectNodeOptions);
  const nodesMetadata = useSelector(selectNodesMetadata);
  const relationshipsMetadata = useSelector(selectRelationshipsMetadata);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Knowledge Graph',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getNodeOptionsAsync({ workspaceId }));
      dispatch(getNodesMetadataAsync({ workspaceId }));
      dispatch(getRelationshipsMetadataAsync({ workspaceId }));
      // dispatch(getGraphSizeAsync({ workspaceId }));
      // dispatch(getGraphLegendAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace) {
      const correlationId = uuidv4();
      const workspaceId = selectedWorkspace.id;
      const domain = selectedWorkspace.key;
      let req;
      if (value.selectedTypes) {
        const selectedTypes = Object.entries(value.selectedTypes)
          .filter(([_, v]) => v)
          .map(([k, _]) => k);

        req = { ...value, selectedTypes };
      } else {
        req = value;
      }
      dispatch(runGraphQuery({
        ...req,
        correlationId,
        domain,
        workspaceId,
      }));
      correlationIdRef.current = correlationId;
      setValue(cur => ({ ...cur, ...value }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const graph = graphs[correlationIdRef.current];
    if (graph) {
      let g;
      let selectedTypes;

      if (value.selectedTypes) {
        const types = getSelectedTypes(graph, value.selectedTypes);
        g = filterGraph(graph, types);
        selectedTypes = value.selectedTypes;
      } else {
        g = graph;
        selectedTypes = Object.keys(graph.legend).reduce((a, k) => {
          a[k] = true;
          return a;
        }, {});
      }

      const nodes = [];
      for (const node of g.nodes) {
        if (value.nodeStyle === 'icon') {
          if (node.icon) {
            nodes.push({ ...node, icon: icons[node.icon].image });
          } else if (node.type === 'Document') {
            nodes.push({ ...node, icon: icons.document.image });
          } else if (node.type === 'Chunk') {
            nodes.push({ ...node, icon: icons.chunk.image });
          } else {
            nodes.push({ ...node, icon: null });
          }
        } else {
          nodes.push({ ...node, icon: null });
        }
      }

      g = { ...g, nodes };

      let dataSource, defaultColumns;
      if (value.tableView === 'nodes') {
        const isAdjacency = value.tableType === 'adjacency-matrix';
        dataSource = getDataSource(g, isAdjacency);
        defaultColumns = getDefaultColumns(g, isAdjacency);
      } else {
        dataSource = getEdges(g);
        defaultColumns = getEdgeMappingColumns(g, value.tableParams);
      }

      const legend = Object.entries(graph.legend).map(([k, v]) => {
        const icon = value.nodeStyle === 'icon' ? v.icon : null;
        return [k, v.color, icon];
      });

      setValue(cur => ({
        ...cur,
        dataSource,
        defaultColumns,
        graph: g,
        legend,
        selectedTypes,
      }));
    }
  }, [graphs]);

  const handleChange = (changes) => {
    // console.log('changes:', changes);
    const correlationId = uuidv4();
    const workspaceId = selectedWorkspace.id;
    const domain = selectedWorkspace.key;

    let updates = { ...changes };
    if ('selectAll' in changes) {
      let selectedTypes;
      if (changes.selectAll) {
        selectedTypes = value.legend.reduce((a, [label, _]) => {
          a[label] = true;
          return a;
        }, {});
      } else {
        selectedTypes = value.legend.reduce((a, [label, _]) => {
          a[label] = false;
          return a;
        }, {});
      }
      updates.selectedTypes = selectedTypes;
    }

    let req = { ...value, ...updates };
    if (req.selectedTypes) {
      const selectedTypes = Object.entries(req.selectedTypes)
        .filter(([_, v]) => v)
        .map(([k, _]) => k);

      req = { ...value, ...updates, selectedTypes };
    }
    if ('query' in changes) {
      req.tags = changes.query.state.tags;
    }

    // console.log('value:', value);
    // console.log('updates:', updates);
    // console.log('req:', req);

    dispatch(runGraphQuery({
      ...pick(req, ['cypherQuery', 'filters', 'params', 'queries', 'searchQuery', 'selectedTypes', 'tags']),
      correlationId,
      domain,
      workspaceId,
    }));
    correlationIdRef.current = correlationId;
    setValue({ ...value, ...updates });
  };

  const handleDeleteNode = (values) => {
    dispatch(deleteNodeAsync({
      correlationId: correlationIdRef.current,
      id: values.id,
      type: values.type,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const handleSaveNode = (values) => {
    const node = {
      ...values,
      id: md5(`${values.type}:${values.name.toLowerCase()}`),
      inferred: false,
    };
    const [_, fill, icon] = value.legend.find(l => l[0] === values.type);
    dispatch(createNodeAsync({
      correlationId: correlationIdRef.current,
      fill,
      icon,
      node,
      workspaceId: selectedWorkspace.id,
    }));
  };

  return (
    <div style={{ width: '100%', height: 'calc(100% - 24px)', padding: '20px 0' }}>
      <GraphView2
        nodeOptions={nodeOptions}
        nodesMetadata={nodesMetadata}
        relationshipsMetadata={relationshipsMetadata}
        onChange={handleChange}
        value={value}
        onSaveNode={handleSaveNode}
        onDeleteNode={handleDeleteNode}
      />
    </div>
  );
}