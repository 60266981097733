import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Space, Typography } from 'antd';
import {
  AntDesignOutlined,
  BankOutlined,
  ControlOutlined,
  ExpandOutlined,
  InboxOutlined,
  RightOutlined,
  SafetyCertificateOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import UserContext from '../../contexts/UserContext';
import icons from '../ontology/icons';

import {
  getTasksAsync,
  selectTasks,
} from '../tasks/tasksSlice';

const { Meta } = Card;
const { Title } = Typography;

export function ComplianceOpsHub() {

  const [ready, setReady] = useState(false);

  const tasks = useSelector(selectTasks);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Compliance Ops Hub',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace?.id) {
      dispatch(getTasksAsync({ workspaceId: selectedWorkspace.id }));
      setReady(true);
    }
  }, [selectedWorkspace]);

  const CardTitle = ({ title, icon, index }) => (
    <div className="card-title">
      {icon}
      <div>{title}</div>
    </div>
  );

  return (
    <div id="home">
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: 20, marginLeft: 60 }}>
        {ready ?
          <Space align="start" size="large">
            <Space align="start" direction="vertical" size={60}>
              <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
                <Card
                  title={<CardTitle title="Compliance Operations Management" icon={<SafetyCertificateOutlined style={{ fontSize: '28px' }} />} index={0} />}
                  style={{ width: 873, height: 257, minHeight: 257, backgroundColor: '#fff' }}
                >
                  <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {icons.compliance.icon}
                      <Link>Compliance Obligations and Requirements</Link>
                      <div style={{ flex: 1 }}></div>
                      <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => navigate(`/defined?title=Compliance+Obligations+and+Requirements&types=Obligation,RuleSetRequirement`)}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {icons.loopingArrows.icon}
                      <Link>Changes & Monitoring</Link>
                      <div style={{ flex: 1 }}></div>
                      <Button
                        disabled
                        type="text"
                        icon={<RightOutlined />}
                      />
                    </div>
                  </Space>
                </Card>
              </Space>
              <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
                <Card
                  title={<CardTitle title="Compliance Knowledge Repository" icon={<ShareAltOutlined style={{ fontSize: '28px' }} />} index={0} />}
                  style={{ width: 873, height: 325, minHeight: 326, backgroundColor: '#fff' }}
                >
                  <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {icons.document.icon}
                      <Link to={'/uploads'}>Document Catalog</Link>
                      <div style={{ flex: 1 }}></div>
                      <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => navigate('/uploads')}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {icons.userEngagement.icon}
                      <Link>Roles & Responsibilities</Link>
                      <div style={{ flex: 1 }}></div>
                      <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => navigate(`/defined?title=Roles+%26+Responsibilities&types=OrganizationUnit`)}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {icons.webSettings.icon}
                      <Link>Systems and Tools</Link>
                      <div style={{ flex: 1 }}></div>
                      <Button
                        disabled
                        type="text"
                        icon={<RightOutlined />}
                      />
                    </div>
                  </Space>
                </Card>
              </Space>
            </Space>
            <Space align="start" size="large" wrap={true} style={{ justifyContent: 'center', maxWidth: 1200 }}>
              <Card
                title={<CardTitle title="Actions" icon={<InboxOutlined style={{ fontSize: '28px' }} />} index={0} />}
                style={{ height: 643, minHeight: 643, backgroundColor: '#fff' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', height: 500 }}>
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 8 }}>
                    {Object.values(tasks).map(task => (
                      <Link key={task.id} to={`/tasks/${task.id}`}>{task.name}</Link>
                    ))}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      type="text"
                      icon={<RightOutlined />}
                      onClick={() => navigate('/board')}
                      style={{ background: '#EEE' }}
                    />
                  </div>
                </div>
              </Card>
            </Space>
          </Space>
          :
          <div style={{ color: '#fff', textAlign: 'center' }}>
            To get started, select a Workspace from the top-right menu (next to profile).
          </div>
        }
      </div>
    </div>
  );
}
