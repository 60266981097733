import React from 'react';
import {
  Form,
  Input,
} from 'antd';

const { TextArea } = Input;

export default function TaskForm({ form }) {

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter a name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
      >
        <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
      </Form.Item>
    </Form>
  );
}