import React from 'react';

import accessControl from '../../images/graph/access-control.png';
import approval from '../../images/graph/approval.png';
import blocks from '../../images/graph/blocks.png';
import businessAnalysis from '../../images/graph/business-analysis.png';
import chunk from '../../images/graph/chunk.png';
import compliance from '../../images/graph/compliance.png';
import crisisManagement from '../../images/graph/crisis-management.png';
import document from '../../images/graph/document.png';
import hierarchy from '../../images/graph/hierarchy.png';
import loopingArrows from '../../images/graph/looping-arrows.png';
import organization from '../../images/graph/organization.png';
import qualifications from '../../images/graph/qualifications.png';
import regulatoryCompliance from '../../images/graph/regulatory-compliance.png';
import risk from '../../images/graph/risk.png';
import risk1 from '../../images/graph/risk1.png';
import target from '../../images/graph/target.png';
import taskList from '../../images/graph/task-list.png';
import todoList from '../../images/graph/to-do-list.png';
import userEngagement from '../../images/graph/user-engagement.png';
import webSettings from '../../images/graph/web-settings.png';

export default {
  accesControl: {
    label: 'Access Control',
    icon: <img src={accessControl} width={45} height={45} />,
    image: accessControl,
  },
  approval: {
    label: 'Approval',
    icon: <img src={approval} width={45} height={45} />,
    image: approval,
  },
  blocks: {
    label: 'Blocks',
    icon: <img src={blocks} width={45} height={45} />,
    image: blocks,
  },
  businessAnalysis: {
    label: 'Business Analysis',
    icon: <img src={businessAnalysis} width={45} height={45} />,
    image: businessAnalysis,
  },
  chunk: {
    label: 'Chunk',
    icon: <img src={chunk} width={45} height={45} />,
    image: chunk,
  },
  compliance: {
    label: 'Compliance',
    icon: <img src={compliance} width={45} height={45} />,
    image: compliance,
  },
  crisisManagement: {
    label: 'Crisis Management',
    icon: <img src={crisisManagement} width={45} height={45} />,
    image: crisisManagement,
  },
  document: {
    label: 'Document',
    icon: <img src={document} width={45} height={45} />,
    image: document,
  },
  hierarchy: {
    label: 'Hierarchy',
    icon: <img src={hierarchy} width={45} height={45} />,
    image: hierarchy,
  },
  loopingArrows: {
    label: 'Looping Arrows',
    icon: <img src={loopingArrows} width={45} height={45} />,
    image: loopingArrows,
  },
  organization: {
    label: 'Organization',
    icon: <img src={organization} width={45} height={45} />,
    image: organization,
  },
  qualifications: {
    label: 'Qualifications',
    icon: <img src={qualifications} width={45} height={45} />,
    image: qualifications,
  },
  regulatoryCompliance: {
    label: 'Regulatory Compliance',
    icon: <img src={regulatoryCompliance} width={45} height={45} />,
    image: regulatoryCompliance,
  },
  risk1: {
    label: 'Risk 1',
    icon: <img src={risk} width={45} height={45} />,
    image: risk,
  },
  risk2: {
    label: 'Risk 2',
    icon: <img src={risk1} width={45} height={45} />,
    image: risk1,
  },
  target: {
    label: 'Target',
    icon: <img src={target} width={45} height={45} />,
    image: target,
  },
  taskList: {
    label: 'Task List',
    icon: <img src={taskList} width={45} height={45} />,
    image: taskList,
  },
  todoList: {
    label: 'Todo List',
    icon: <img src={todoList} width={45} height={45} />,
    image: todoList,
  },
  userEngagement: {
    label: 'User Enagement',
    icon: <img src={userEngagement} width={45} height={45} />,
    image: userEngagement,
  },
  webSettings: {
    label: 'Web Settings',
    icon: <img src={webSettings} width={45} height={45} />,
    image: webSettings,
  },
}