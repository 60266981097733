import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, message } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  createTaskAsync,
  getTaskAsync,
  selectTasks,
  selectLoaded,
  updateTaskAsync,
} from './tasksSlice';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const stageOptions = [
  {
    label: 'Backlog',
    value: 'backlog',
  },
  {
    label: 'Assigned',
    value: 'assigned',
  },
  {
    label: 'In Review',
    value: 'review',
  },
];

export function TaskForm() {

  const loaded = useSelector(selectLoaded);
  const tasks = useSelector(selectTasks);

  const [form] = Form.useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  const id = location.pathname.match(/\/tasks\/(.*)/)[1];
  const isNew = id === 'new';
  const task = tasks[id];

  console.log('task:', task);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Task',
    }));
    if (!isNew) {
      dispatch(getTaskAsync(id));
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.message) {
      messageApi.info({
        content: location.state.message,
        duration: 5,
      });
    }
  }, [location]);

  const backToList = () => {
    navigate('/tasks');
  };

  const onFinish = (values) => {
    if (isNew) {
      dispatch(createTaskAsync({
        values: { ...values, workspaceId: selectedWorkspace.id },
      }));
    } else {
      dispatch(updateTaskAsync({ id, values }));
    }
    backToList();
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <>
      {contextHolder}
      <div style={{ background: '#f5f5f5', padding: '16px 0', height: '100%' }}>
        <Form
          form={form}
          {...layout}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={task}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter a name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
          </Form.Item>
          <Form.Item
            label="Stage"
            name="stage"
            initialValue="backlog"
            rules={[
              {
                required: true,
                message: "Please select the stage",
              },
            ]}
          >
            <Select allowClear
              options={stageOptions}
              optionFilterProp="label"
              placeholder="Select stage"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Space>
              <Button type="default" onClick={backToList}>Cancel</Button>
              <Button type="primary" htmlType="submit">Save</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
