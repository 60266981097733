import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const reviewSlice = createSlice({
  name: 'review',
  initialState: {
    review: null,
  },
  reducers: {
    setReview: (state, action) => {
      state.review = action.payload.review;
    },
  }
});

export const {
  setReview,
} = reviewSlice.actions;

export const getReviewAsync = ({ workspaceId, domain }) => async (dispatch) => {
  const url = `/api/risk/review?workspace_id=${workspaceId}&domain=${domain}`;
  const res = await http.get(url);
  dispatch(setReview({ review: res.data }));
};

export const selectReview = (state) => state.review.review;

export default reviewSlice.reducer;
