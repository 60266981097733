import { lazy } from 'react';
import {
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Layout } from 'antd';

import SideMenu from './components/SideMenu';
import Register from './components/accounts/Register';
import Login from './components/accounts/Login';
import Profile from './components/accounts/Profile';
import { Admin } from './features/admin/Admin';
import { ArtefactsList } from './features/artefacts/ArtefactsList';
import { ArtefactForm } from './features/artefacts/ArtefactForm';
import { AppChat } from './features/chat/AppChat';
import { ComplianceOpsHub } from './features/hub/ComplianceOpsHub';
import { DefinedView } from './features/defined/DefinedView';
import { UploadForm } from './features/uploader/UploadForm';
import { FileUploader } from './features/uploader/FileUploader';
import { Graph2 } from './features/graph/Graph2';
import { Home } from './features/home/Home';
import { InferenceRunsList } from './features/inference/InferenceRunsList';
import { InferenceRunForm } from './features/inference/InferenceRunForm';
import { Interview } from './features/interviews/Interview';
import { MetricsList } from './features/metrics/MetricsList';
import { MetricForm } from './features/metrics/MetricForm';
import { MetricSetsList } from './features/metrics/MetricSetsList';
import { MetricSetForm } from './features/metrics/MetricSetForm';
import { NotebooksList } from './features/notebooks/NotebooksList';
import { Notebook } from './features/notebooks/Notebook';
import { OntologiesList } from './features/ontology/OntologiesList';
import { OntologyEditor } from './features/ontology/OntologyEditor';
import { OpsDashboard } from './features/home/OpsDashboard';
import { ProfileView } from './features/profile/ProfileView';
import { Report } from './features/report/Report';
import { Review } from './features/review/Review';
import { SearchForm } from './features/search/SearchForm';
import { SectionsList } from './features/sections/SectionsList';
import { SectionForm } from './features/sections/SectionForm';
import { SettingsList } from './features/settings/SettingsList';
import { SettingForm } from './features/settings/SettingForm';
import { TaskForm } from './features/tasks/TaskForm';
import { TasksBoard } from './features/tasks/TasksBoard';
import { TasksList } from './features/tasks/TasksList';
import { Tutor } from './features/tutor/Tutor';
import { UserForm } from './features/users/UserForm';
import { UsersList } from './features/users/UsersList';
import { WorkspaceForm } from './features/workspaces/WorkspaceForm';
import { WorkspacesList } from './features/workspaces/WorkspacesList';
import WithPrivateRoute from './utils/WithPrivateRoute';

const APP = process.env.REACT_APP_NAME === 'Compliance Copilot' ? 'compliancecopilot' : 'pqacademy';

const Navbar = lazy(() => import('./components/Navbar'));

const { Header, Content, Footer } = Layout;

function MyHeader({ isDarkMode }) {
  return (
    <Header className="site-layout-background"
      style={{
        background: isDarkMode ? '#001529' : 'inherit',
        padding: '0 16px',
      }}
    >
      <Navbar />
    </Header>
  );
}

const router = ({ currentUser, isAdmin, isDarkMode }) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="*" element={
          <WithPrivateRoute>
            <Layout style={{ minHeight: '100vh' }} className={isDarkMode ? 'dark' : 'light' + ' jost-font'}>
              <SideMenu
                isAdmin={isAdmin}
                isDarkMode={isDarkMode}
                currentUser={currentUser}
              />
              <Layout className="site-layout">
                <MyHeader isDarkMode={isDarkMode} />
                <Content style={{ margin: '0 16px' }}>
                  <Routes>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/artefacts/:id" element={<ArtefactForm />} />
                    <Route path="/artefacts" element={<ArtefactsList />} />
                    <Route path="/chat" element={<AppChat />} />
                    <Route path="/graph" element={<Graph2 />} />
                    <Route path="/inference/:id" element={<InferenceRunForm />} />
                    <Route path="/inference" element={<InferenceRunsList />} />
                    <Route path="/interviews" element={<Interview />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route path="/uploads/:id" element={<UploadForm />} />
                    <Route path="/uploads" element={<FileUploader />} />
                    <Route path="/metrics/:id" element={<MetricForm />} />
                    <Route path="/metrics" element={<MetricsList />} />
                    <Route path="/metric-sets/:id" element={<MetricSetForm />} />
                    <Route path="/metric-sets" element={<MetricSetsList />} />
                    <Route path="/notebooks/:id" element={<Notebook />} />
                    <Route path="/notebooks" element={<NotebooksList />} />
                    <Route path="/ontologies/:id" element={<OntologyEditor />} />
                    <Route path="/ontologies" element={<OntologiesList />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/review" element={<Review />} />
                    <Route path="/search" element={<SearchForm />} />
                    <Route path="/sections/:id" element={<SectionForm />} />
                    <Route path="/sections" element={<SectionsList />} />
                    <Route path="/settings/:id" element={<SettingForm />} />
                    <Route path="/settings" element={<SettingsList />} />
                    <Route path="/tutor" element={<Tutor />} />
                    <Route path="/users/:id/edit" element={<UserForm />} />
                    <Route path="/users/:id" element={<ProfileView />} />
                    <Route path="/users" element={<UsersList />} />
                    <Route path="/workspaces/:id" element={<WorkspaceForm />} />
                    <Route path="/workspaces" element={<WorkspacesList />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/dashboard" element={<OpsDashboard />} />
                    <Route path="/hub" element={<ComplianceOpsHub />} />
                    <Route path="/defined" element={<DefinedView />} />
                    <Route path="/board" element={<TasksBoard />} />
                    <Route path="/tasks/:id" element={<TaskForm />} />
                    <Route path="/tasks" element={<TasksList />} />
                    <Route path="/" element={APP === 'pqacademy' ? <Tutor /> : <OpsDashboard />} />
                  </Routes>
                </Content>
                <Footer style={{ textAlign: 'center' }}>{process.env.REACT_APP_NAME} ©2024</Footer>
              </Layout>
            </Layout>
          </WithPrivateRoute>
        } />
      </>
    )
  );
};

export default router;