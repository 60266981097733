import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ControlledBoard, moveCard } from '@caldwell619/react-kanban';
import omit from 'lodash.omit';

import AddTaskModal from '../../components/AddTaskModal';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import '@caldwell619/react-kanban/dist/styles.css';

import {
  createTaskAsync,
  getTasksAsync,
  selectTasks,
  updateTaskAsync,
} from './tasksSlice';

export function TasksBoard() {

  const [board, setBoard] = useState(null);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);

  const tasks = useSelector(selectTasks);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: () => setAddTaskModalOpen(true),
      title: 'Active Tasks',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      dispatch(getTasksAsync({ workspaceId: selectedWorkspace.id }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const ts = Object.values(tasks || {});
    const data = {
      columns: [
        {
          id: 'backlog',
          title: 'Backlog',
          cards: getCards(ts, 'backlog'),
        },
        {
          id: 'assigned',
          title: 'Assigned',
          cards: getCards(ts, 'assigned'),
        },
        {
          id: 'review',
          title: 'In Review',
          cards: getCards(ts, 'review'),
        },
      ]
    };
    setBoard({ ...data });
  }, [tasks]);

  const handleAddTaskModalCancel = () => {
    setAddTaskModalOpen(false);
  };

  const handleAddTaskSubmit = ({ values }) => {
    // console.log('values:', values);
    dispatch(createTaskAsync({
      values: {
        ...values,
        stage: 'backlog',
        workspaceId: selectedWorkspace.id,
      },
    }))
    setAddTaskModalOpen(false);
  };

  const handleCardAdd = () => { };

  const handleCardMove = (card, source, dest) => {
    // console.log({ card, source, dest });
    dispatch(updateTaskAsync({
      id: card.id,
      values: {
        ...omit(tasks[card.id], ['id']),
        stage: dest.toColumnId,
      },
    }));
    setBoard(cur => moveCard(cur, source, dest));
  };

  const getCards = (tasks, stage) => {
    return tasks
      .filter(t => t.stage === stage)
      .map(t => {
        return {
          id: t.id,
          title: (
            <Link to={`/tasks/${t.id}`}>
              {t.name} <LinkOutlined />
            </Link>
          ),
          description: (
            <>
              <Typography.Paragraph ellipsis={{ rows: 4 }} style={{ fontSize: '14px' }}>
                {t.description}
              </Typography.Paragraph>
            </>
          ),
          createdAt: t.created,
          assigneeId: t.createdBy,
        }
      });
  };

  if (!board) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <>
      <AddTaskModal
        onCancel={handleAddTaskModalCancel}
        onSubmit={handleAddTaskSubmit}
        open={addTaskModalOpen}
      />
      <ControlledBoard
        allowAddCard={false}
        allowRemoveColumn={false}
        onCardDragEnd={handleCardMove}
        onCardRemove={({ board, card, column }) => {
          dispatch(updateTaskAsync({
            id: card.id,
            values: {
              ...omit(tasks[card.id], ['id']),
              stage: 'complete',
            },
          }));
        }}
        // doesn't appear implemented, will need to fork
        onNewCardConfirm={handleCardAdd}
      >
        {board}
      </ControlledBoard>
    </>
  );
}