import { useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Switch,
} from 'antd';
import omitBy from 'lodash.omitby';

const { TextArea } = Input;

const subFieldLayout = {
  colon: false,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const PropertyLabel = ({ value }) => {
  return (
    <div>{value}</div>
  );
};

const InputControl = ({ onChange, value, dataType }) => {
  if (dataType === 'BOOLEAN') {
    return (
      <Switch onChange={onChange} checked={value} />
    );
  }
  return (
    <Input onChange={onChange} value={value} />
  );
};

export default function NodeInstanceForm({ form, schema, sourceType }) {

  // console.log('schema:', schema);

  const typeValue = Form.useWatch('type', form);
  const propertiesValue = Form.useWatch('properties', form);

  let nodeOptions;
  if (sourceType) {
    nodeOptions = Object.values(schema[sourceType].relationships || [])
      .filter(label => !['Document', 'Chunk'].includes(label))
      .map(label => ({
        label,
        value: label,
      }));
  } else {
    nodeOptions = Object.keys(schema || [])
      .filter(label => !['Document', 'Chunk'].includes(label))
      .map(label => ({
        label,
        value: label,
      }));
  }

  useEffect(() => {
    if (typeValue) {
      const props = schema[typeValue].properties;
      const propsMap = props.reduce((a, p) => {
        a[p.name] = p;
        return a;
      }, {});
      // const common = [
      //   propsMap['name'],
      //   propsMap['description'],
      //   propsMap['inferred'],
      // ].filter(v => v);
      const common = [];
      const rest = Object.values(omitBy(propsMap, p => ['id', 'name', 'description', 'inferred'].includes(p.name)));
      rest.sort((a, b) => a.name < b.name ? -1 : 1);
      const properties = [...common, ...rest];
      form.setFieldValue('properties', properties);
    } else {
      form.setFieldValue('properties', null);
    }
  }, [typeValue]);

  if (!nodeOptions.length) {
    return (
      <div>No valid connecting nodes to add.</div>
    );
  }
  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: 'Please select the type',
          },
        ]}
      >
        <Select allowClear
          options={nodeOptions}
        />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter a name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
      >
        <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
      </Form.Item>
      {propertiesValue?.length ?
        <>
          <div
            style={{ fontWeight: 600, marginBottom: 8 }}
          >
            Properties
          </div>
          <Form.Item style={{ height: 174, overflowY: 'auto' }}>
            <Form.List name="properties">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item key={field.name}>
                      <Form.Item
                        {...subFieldLayout}
                        label={index === 0 ? 'Property' : ''}
                        name={[field.name, 'name']}
                        style={{ display: 'inline-block', width: 'calc(40% - 4px)', marginBottom: 0 }}
                      >
                        <PropertyLabel />
                      </Form.Item>
                      <Form.Item
                        {...subFieldLayout}
                        label={index === 0 ? 'Value' : ''}
                        name={[field.name, 'value']}
                        style={{ display: 'inline-block', width: 'calc(60% - 4px)', marginBottom: 0, marginLeft: 8 }}
                      >
                        <InputControl dataType={form.getFieldValue('properties')[index].type} />
                      </Form.Item>
                    </Form.Item>
                  ))}
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
        : null
      }
    </Form>
  );
}