import React from 'react';
import {
  Form,
  Modal,
} from 'antd';

import NodeInstanceForm from './NodeInstanceForm';

export default function AddNodeInstanceModal({
  onCancel,
  onSubmit,
  open,
  schema,
  sourceType,
}) {

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onSubmit({ values });
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okText="Save"
      title="Add Node"
    >
      <NodeInstanceForm form={form} schema={schema} sourceType={sourceType} />
    </Modal>
  );
}