import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    loaded: false,
    loading: false,
    tasks: {},
  },
  reducers: {
    removeTasks: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.tasks[id];
      }
    },
    resetTasks: (state) => {
      state.tasks = {};
    },
    setTasks: (state, action) => {
      for (const task of action.payload.tasks) {
        state.tasks[task.id] = task;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeTasks,
  resetTasks,
  setTasks,
  startLoad,
} = tasksSlice.actions;

export const getTasksAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/tasks?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setTasks({ tasks: res.data }));
};

export const getTaskAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/tasks/${id}`;
  const res = await http.get(url);
  dispatch(setTasks({ tasks: [res.data] }));
};

export const createTaskAsync = ({ values }) => async (dispatch) => {
  const url = '/api/tasks';
  const res = await http.post(url, values);
  dispatch(setTasks({ tasks: [res.data] }));
};

export const updateTaskAsync = ({ id, values }) => async (dispatch) => {
  const url = `/api/tasks/${id}`;
  const res = await http.put(url, values);
  dispatch(setTasks({ tasks: [res.data] }));
};

export const deleteTasksAsync = (ids) => async (dispatch) => {
  const url = `/api/tasks?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeTasks({ ids }));
};

export const selectLoaded = (state) => state.tasks.loaded;

export const selectLoading = (state) => state.tasks.loading;

export const selectResult = (state) => state.tasks.result;

export const selectTasks = (state) => state.tasks.tasks;

export default tasksSlice.reducer;
