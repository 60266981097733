import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Descriptions,
  Drawer,
  Input,
  Layout,
  Pagination,
  Segmented,
  Space,
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { GraphCanvas, useSelection } from 'reagraph';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import lowerCase from 'lodash.lowercase';
import YouTube from 'react-youtube';
import useLocalStorageState from 'use-local-storage-state';

import { CypherQueryBuilder } from './CypherQueryBuilder';
import { EditableTable } from './EditableTable';

const { Search, TextArea } = Input;
const { Content, Sider } = Layout;

const theme = {
  canvas: { background: '#FBFBFB' },
  node: {
    fill: '#7CA0AB',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.2,
    label: {
      color: '#2A6475',
      stroke: '#fff',
      activeColor: '#1DE9AC'
    },
    subLabel: {
      color: '#ddd',
      stroke: 'transparent',
      activeColor: '#1DE9AC'
    }
  },
  lasso: {
    border: '1px solid #55aaff',
    background: 'rgba(75, 160, 255, 0.1)'
  },
  ring: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  edge: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475',
      activeColor: '#1DE9AC',
      fontSize: 6
    }
  },
  arrow: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  cluster: {
    stroke: '#D8E6EA',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475'
    }
  }
};

const opts = {
  height: '112.5',
  width: '200',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

const GraphView = ({
  defaultLayout,
  height,
  searchBarPaddingLeft,
  nodesMetadata,
  relationshipsMetadata,
  onChange,
  onCypherQuery,
  onImageChange,
  onQuery,
  onChangeTable,
  value,
  tableView,
  setTableView,
  tableType,
  setTableType,
  graphLegend,
  onAddColumn,
  onSaveProperty,
  pageOptions,
  onPageChange,
  queryBuilderState,
  nodeOptions,
}) => {

  console.log('value:', value);

  const initialLayout = value.layout || defaultLayout || 'graph';

  const [fetchType, setFetchType] = useLocalStorageState('fetch-type', { defaultValue: 'search' });
  const [layout, setLayout] = useState(initialLayout);
  const [player, setPlayer] = useState(null);
  const [searchText, setSearchText] = useState(value?.query || '');
  const [selectAllState, setSelectAllState] = useState(0);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const graphRef = useRef();
  const correlationRef = useRef({});
  const cypherInput = useRef();

  const { selections, clearSelections, actives, onNodeClick, onCanvasClick } = useSelection({
    ...value?.graph,
    ref: graphRef,
    type: 'single',
    pathSelectionType: 'all',
  });

  const selectedNode = useMemo(() => {
    if (selectedNodeId && value?.graph) {
      const { graph } = value;
      const nodeMap = graph.nodes.reduce((a, node) => {
        a[node.id] = node;
        return a;
      }, {});
      const node = nodeMap[selectedNodeId];
      if (node) {
        const properties = Object.entries(node.data || {})
          .filter(([k, v]) => k !== 'id' && typeof v !== 'undefined')
          .map(([k, v]) => {
            if (typeof v === 'boolean') {
              return [k, v ? 'true' : 'false'];
            }
            return [k, v];
          });
        properties.sort((a, b) => a[0] < b[0] ? -1 : 1);
        const nodeAsSourceEdges = [];
        const nodeAsTargetEdges = [];
        for (const edge of graph.edges) {
          if (edge.source === node.id) {
            const targetNode = nodeMap[edge.target];
            nodeAsSourceEdges.push(({
              type: edge.label,
              id: edge.target,
              target: targetNode.type,
              name: targetNode.label,
            }));
          }
          if (edge.target === node.id) {
            const sourceNode = nodeMap[edge.source];
            nodeAsTargetEdges.push({
              type: edge.label,
              id: edge.source,
              source: sourceNode.type,
              name: sourceNode.label,
            });
          }
        }
        return {
          id: node.id,
          type: node.type,
          label: node.label,
          properties,
          nodeAsSourceEdges,
          nodeAsTargetEdges,
        };
      }
    }
    return null;
  }, [selectedNodeId]);

  // useEffect(() => {
  //   if (typeof onChange === 'function') {
  //     onChange({
  //       ...value,
  //       layout,
  //       query: searchText,
  //     });
  //   }
  // }, [layout]);

  useEffect(() => {
    if (layout === 'table') {
      setSelectedNodeId(null);
    }
  }, [layout]);

  useEffect(() => {
    if (value?.correlationId && !correlationRef.current[value.correlationId]) {
      correlationRef.current[value.correlationId] = true;
      setTimeout(() => {
        const image = graphRef.current?.exportCanvas();
        if (image) {
          onImageChange(image);
        }
      }, 2000);
    }
  }, [value]);

  const handleAdd = () => {

  };

  const handleDelete = () => {

  };

  const handleSave = (values) => {
    console.log('values:', values);
  };

  const handleCanvasClick = (ev) => {
    setSelectedNodeId(null);
    onCanvasClick(ev);
  };

  const handleCypherQuery = () => {
    if (cypherInput.current) {
      onCypherQuery(cypherInput.current.resizableTextArea.textArea.value);
    }
  };

  const handleNodeClick = (node) => {
    if (selectedNodeId === node.id) {
      setSelectedNodeId(null);
      clearSelections();
    } else {
      setSelectedNodeId(node.id);
      onNodeClick(node);
    }
  };

  const handleTypeChange = (label, checked) => {
    const types = { ...value.selectedTypes, [label]: checked };
    if (typeof onChange === 'function') {
      onChange({
        ...value,
        layout,
        query: searchText,
        selectedTypes: types,
      });
    }
    setSelectAllState(0);
  };

  const onDrawerClose = () => {
    setSelectedNodeId(null);
    clearSelections();
  };

  const onReady = (ev) => {
    setPlayer(ev.target);
  };

  const onSelectAllChange = (on) => {
    const types = Object.entries(graphLegend)
      .reduce((a, [label, _]) => {
        a[label] = on;
        return a;
      }, {});

    if (typeof onChange === 'function') {
      onChange({
        ...value,
        layout,
        query: searchText,
        selectedTypes: types,
      });
    }
  };

  const onSearchChange = (ev) => {
    setSearchText(ev.target.value);
  };

  const onSearch = (query) => {
    if (typeof onChange === 'function') {
      onChange({
        ...value,
        layout,
        query,
      });
    }
  };

  let queryBuilderLabel = 'Query Builder';
  if (queryBuilderState?.tags?.length) {
    queryBuilderLabel = (
      <div style={{ fontWeight: 600 }}>
        Query Builder<span style={{ color: '#FF0000' }}>*</span>
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {onChange ?
          <>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center', paddingTop: 0 }}>
              <Segmented
                onChange={setFetchType}
                value={fetchType}
                size="small"
                style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                options={[
                  {
                    label: 'Search',
                    value: 'search',
                  },
                  {
                    label: queryBuilderLabel,
                    value: 'query',
                  },
                  {
                    label: 'Cypher Query',
                    value: 'cypher',
                  },
                ]}
              />
              <div style={{ flex: 1 }}></div>
              <Segmented
                onChange={setLayout}
                value={layout}
                size="small"
                style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                options={[
                  {
                    label: 'Graph',
                    value: 'graph',
                  },
                  {
                    label: 'Table',
                    value: 'table',
                  },
                ]}
              />
            </div>
            <div style={{ display: 'flex', gap: 8, paddingTop: 16 }}>
              <div style={{ minHeight: 88 }}>
                {fetchType === 'search' ?
                  <Search allowClear
                    value={searchText}
                    onChange={onSearchChange}
                    onSearch={onSearch}
                    placeholder="Enter natural language query..."
                    style={{ width: 500 }}
                  />
                  : null
                }
                {fetchType === 'query' && !isEmpty(nodesMetadata) ?
                  <CypherQueryBuilder
                    nodesMetadata={nodesMetadata}
                    relationshipsMetadata={relationshipsMetadata}
                    onQuery={onQuery}
                    state={queryBuilderState}
                  />
                  : null
                }
                {fetchType === 'cypher' ?
                  <Space direction="vertical" size={[0, 8]}>
                    <TextArea
                      ref={cypherInput}
                      autoSize={{ minRows: 1, maxRows: 14 }}
                      placeholder="Enter cypher query..."
                      // style={{ borderRadius: 16, width: 755 }}
                      style={{ borderRadius: 16, width: 500 }}
                    />
                    <Button
                      size="small"
                      type="primary"
                      onClick={handleCypherQuery}
                    >
                      Query
                    </Button>
                  </Space>
                  : null
                }
              </div>
              <div style={{ flex: 1 }}></div>
              {layout === 'table' ?
                <div>
                  <Segmented
                    onChange={setTableView}
                    value={tableView}
                    style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                    options={[
                      {
                        label: 'Nodes',
                        value: 'nodes',
                      },
                      {
                        label: 'Relationships',
                        value: 'edges',
                      },
                    ]}
                  />
                </div>
                : null
              }
            </div>
          </>
          :
          <div style={{ fontWeight: 600 }}>{searchText}</div>
        }
        {layout === 'graph' && value?.graph ?
          <>
            <div style={{ flex: 1, position: 'relative', width: '100%', minWidth: 500 }}>
              <div className="graph-legend">
                <fieldset>
                  <legend>Legend</legend>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <label></label>
                    <div className="color-circle" style={{ backgroundColor: '#fff' }} />
                    <Checkbox
                      checked={selectAllState === 1}
                      indeterminate={selectAllState == 0}
                      onChange={(ev) => {
                        const checked = ev.target.checked
                        setSelectAllState(checked ? 1 : -1);
                        onSelectAllChange(checked);
                      }}
                    />
                  </div>
                  {value?.legend?.map(([label, color]) => (
                    <div key={label} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <label>{label}</label>
                      <div className="color-circle" style={{ backgroundColor: color }} />
                      <Checkbox
                        checked={value.selectedTypes[label]}
                        onChange={(ev) => handleTypeChange(label, ev.target.checked)}
                      />
                    </div>
                  ))}
                </fieldset>
              </div>
              <GraphCanvas
                {...value.graph}
                ref={graphRef}
                selections={selections}
                actives={actives}
                onCanvasClick={handleCanvasClick}
                onNodeClick={handleNodeClick}
                theme={theme}
              />
            </div>
            <div style={{ height: 24, paddingTop: 8 }}>
              <Pagination
                {...pageOptions}
                onChange={onPageChange}
                size="small"
                pageSizeOptions={[100, 1000, 10000]}
                defaultPageSize={1000}
              />
            </div>
          </>
          : null
        }
        {layout === 'table' && value ?
          <div style={{ height: 'calc(100vh - 168px)', overflow: 'auto' }}>
            <EditableTable
              dataSource={value.dataSource}
              defaultColumns={value.defaultColumns}
              tableType={tableType}
              setTableType={setTableType}
              tableView={tableView}
              onChangeTable={onChangeTable}
              onAddColumn={onAddColumn}
              onSaveProperty={onSaveProperty}
              // onAdd={handleAdd}
              // onDelete={handleDelete}
              onSave={handleSave}
              nodeOptions={nodeOptions}
              onQuery={onQuery}
            />
          </div>
          : null
        }
      </div>
      {/* {layout === 'graph' ?
          <Sider
            open={!!selectedNode}
            theme="light"
            width={selectedNodeId ? 500 : 0}
            style={{ height: height || 'calc(100vh - 164px)', overflowY: 'scroll', padding: 16 }}
          >
            {selectedNode ?
              <>
                <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 22 }}>
                  Properties
                </div>
                <Descriptions bordered column={1} size="small">
                  <Descriptions.Item key={'__id'}
                    label={'id'}
                    labelStyle={{ width: 130 }}
                  >
                    {selectedNode.id}
                  </Descriptions.Item>
                  <Descriptions.Item key={'__type'}
                    label={'type'}
                    labelStyle={{ width: 130 }}
                  >
                    {selectedNode.type}
                  </Descriptions.Item>
                  <Descriptions.Item key={'__label'}
                    label={'label'}
                    labelStyle={{ width: 130 }}
                    style={{ borderBottom: '2px solid #ccc' }}
                  >
                    {selectedNode.label}
                  </Descriptions.Item>
                  {selectedNode.properties.map(([key, val]) =>
                    <Descriptions.Item key={key}
                      label={lowerCase(key)}
                      labelStyle={{ width: 130 }}
                    >
                      {val}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                {selectedNode.nodeAsTargetEdges?.length ?
                  <>
                    <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                      Incoming relationships
                    </div>
                    <Descriptions bordered column={1} size="small">
                      {selectedNode.nodeAsTargetEdges?.map((rel) =>
                        Object.entries(rel).map(([key, val], i) =>
                          <Descriptions.Item key={key}
                            label={lowerCase(key)}
                            labelStyle={{ width: 130 }}
                            style={{ borderBottom: i === Object.keys(rel).length - 1 ? '2px solid #ccc' : 'inherit' }}
                          >
                            {val}
                          </Descriptions.Item>
                        )
                      )}
                    </Descriptions>
                  </>
                  : null
                }
                {selectedNode.nodeAsSourceEdges?.length ?
                  <>
                    <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                      Outgoing relationships
                    </div>
                    <Descriptions bordered column={1} size="small">
                      {selectedNode.nodeAsSourceEdges?.map((rel) =>
                        Object.entries(rel).map(([key, val], i) =>
                          <Descriptions.Item key={key}
                            label={lowerCase(key)}
                            labelStyle={{ width: 130 }}
                            style={{ borderBottom: i === Object.keys(rel).length - 1 ? '2px solid #ccc' : 'inherit' }}
                          >
                            {val}
                          </Descriptions.Item>
                        )
                      )}
                    </Descriptions>
                  </>
                  : null
                }
              </>
              : null
            }
          </Sider>
          :
          <Sider width={0}></Sider>
        } */}
      <Drawer
        closable={true}
        onClose={onDrawerClose}
        open={!!selectedNodeId}
        placement="right"
        title="Properties"
        width={500}
      >
        <>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item key={'__id'}
              label={'id'}
              labelStyle={{ width: 130 }}
            >
              {selectedNode?.id}
            </Descriptions.Item>
            <Descriptions.Item key={'__type'}
              label={'type'}
              labelStyle={{ width: 130 }}
            >
              {selectedNode?.type}
            </Descriptions.Item>
            <Descriptions.Item key={'__label'}
              label={'label'}
              labelStyle={{ width: 130 }}
              style={{ borderBottom: '2px solid #ccc' }}
            >
              {selectedNode?.label}
            </Descriptions.Item>
            {selectedNode?.properties.map(([key, val]) =>
              <Descriptions.Item key={key}
                label={lowerCase(key)}
                labelStyle={{ width: 130 }}
              >
                {Array.isArray(val) ? val.join(', ') : val}
                {key === 'video_id' && val ?
                  <div style={{ marginTop: 8 }}>
                    <YouTube
                      onReady={onReady}
                      opts={opts}
                      videoId={val}
                    />
                  </div>
                  : null
                }
              </Descriptions.Item>
            )}
          </Descriptions>
          {selectedNode?.nodeAsTargetEdges?.length ?
            <>
              <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                Incoming relationships
              </div>
              <Descriptions bordered column={1} size="small">
                {selectedNode?.nodeAsTargetEdges?.map((rel) =>
                  Object.entries(rel).map(([key, val], i) =>
                    <Descriptions.Item key={key}
                      label={lowerCase(key)}
                      labelStyle={{ width: 130 }}
                      style={{ borderBottom: i === Object.keys(rel).length - 1 ? '2px solid #ccc' : 'inherit' }}
                    >
                      {val}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </>
            : null
          }
          {selectedNode?.nodeAsSourceEdges?.length ?
            <>
              <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                Outgoing relationships
              </div>
              <Descriptions bordered column={1} size="small">
                {selectedNode?.nodeAsSourceEdges?.map((rel) =>
                  Object.entries(rel).map(([key, val], i) =>
                    <Descriptions.Item key={key}
                      label={lowerCase(key)}
                      labelStyle={{ width: 130 }}
                      style={{ borderBottom: i === Object.keys(rel).length - 1 ? '2px solid #ccc' : 'inherit' }}
                    >
                      {val}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </>
            : null
          }
        </>
      </Drawer>
    </>
  )
};

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.value, nextProps.value);
};

export default React.memo(GraphView, areEqual);
