import { useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Descriptions,
  Drawer,
  Input,
  Modal,
  Pagination,
  Segmented,
  Space,
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { GraphCanvas, RadialMenu, useSelection } from 'reagraph';
import YouTube from 'react-youtube';
import isEmpty from 'lodash.isempty';
import lowerCase from 'lodash.lowercase';

import AddNodeInstanceModal from '../components/AddNodeInstanceModal';
import icons from '../features/ontology/icons';

import { CypherQueryBuilder } from './CypherQueryBuilder';
import { EditableTable } from './EditableTable';

const { Search, TextArea } = Input;

const theme = {
  canvas: { background: '#FBFBFB' },
  node: {
    fill: '#7CA0AB',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.2,
    label: {
      color: '#2A6475',
      stroke: '#fff',
      activeColor: '#1DE9AC'
    },
    subLabel: {
      color: '#ddd',
      stroke: 'transparent',
      activeColor: '#1DE9AC'
    }
  },
  lasso: {
    border: '1px solid #55aaff',
    background: 'rgba(75, 160, 255, 0.1)'
  },
  ring: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  edge: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475',
      activeColor: '#1DE9AC',
      fontSize: 6
    }
  },
  arrow: {
    fill: '#D8E6EA',
    activeFill: '#1DE9AC'
  },
  cluster: {
    stroke: '#D8E6EA',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#fff',
      color: '#2A6475'
    }
  }
};

const opts = {
  height: '112.5',
  width: '200',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

export function GraphView2({
  nodeOptions,
  nodesMetadata,
  relationshipsMetadata,
  onAddColumn,
  onChange,
  onSaveProperty,
  value,
  onSaveNode,
  onDeleteNode,
}) {
  /*
    onChange:
      fetchType: oneof 'search' | 'query' | 'cypher'
      layout: oneof 'graph' | 'table'
      searchQuery: search string (antd select)
      query: state: { currentState: List[StateObject], tags: List[str] }, queries: List[str] (CypherQueryBuilder) filters: List[str], params: Dict[str, Any] (EditableTable)
      cypherQuery: cypher string
      tableView: oneof 'nodes' | 'edges'
      selectAll: boolean
      selectedTypes: Dict[node type str, boolean]
      page: int
      pageSize: int
      tableType: oneof 'cell-list' | 'adjacency-matrix'
      tableParams: {
        pagination: {
          current: int,
          pageSize: int,
          hideOnSinglePage: boolean,
          position: List[str] e.g. ['topLeft'],
          size: str e.g. 'small',
        },
        filters: {},
      }
      nodeStyle: oneof 'color' | 'icons'
  */

  // console.log('value:', value);

  const [addNodeInstanceModalOpen, setAddNodeInstanceModalOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [searchText, setSearchText] = useState(value.searchQuery || '');
  const [selectAllState, setSelectAllState] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const cypherInput = useRef();
  const graphRef = useRef();
  const nodeRef = useRef(new Map());

  const { selections, clearSelections, actives, onNodeClick, onCanvasClick } = useSelection({
    ...value.graph,
    ref: graphRef,
    type: 'multiModifier',
    pathSelectionType: 'all',
  });

  const handleAddNodeInstance = (data) => {
    setSelectedLabel(data.data.type);
    setAddNodeInstanceModalOpen(true);
  };

  const handleAddNodeInstanceModalCancel = () => {
    setAddNodeInstanceModalOpen(false);
    setSelectedLabel(null);
  };

  const handleAddNodeInstanceSubmit = (data) => {
    console.log('data:', data);
    onSaveNode(data.values);
    setAddNodeInstanceModalOpen(false);
    setSelectedLabel(null);
  };

  const handleCanvasClick = (ev) => {
    setSelectedNodeId(null);
    onCanvasClick(ev);
  };

  const handleDeleteNodeInstance = (data) => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          Deleting {data.type} : {data.label}
        </div>
      ),
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        onDeleteNode(data);
      },
    });
  };

  const handleNodeDoubleClick = (node) => {
    if (selectedNodeId === node.id) {
      setSelectedNodeId(null);
      clearSelections();
    } else {
      setSelectedNodeId(node.id);
      // onNodeClick(node);
    }
  };

  const handleSave = (values) => {
    // console.log('values:', values);
  };

  const handleSearchChange = (ev) => {
    setSearchText(ev.target.value);
  };

  const handleSelectAllChange = (ev) => {
    const { checked } = ev.target;
    setSelectAllState(checked ? 1 : -1);
    onChange({ selectAll: checked });
  };

  const handleTypeSelection = (label, checked) => {
    setSelectAllState(0);
    onChange({ selectedTypes: { ...value.selectedTypes, [label]: checked } })
  };

  const onDrawerClose = () => {
    setSelectedNodeId(null);
    clearSelections();
  };

  const onReady = (ev) => {
    // setPlayer(ev.target);
  };

  const selectedNode = useMemo(() => {
    if (selectedNodeId && value?.graph) {
      const { graph } = value;
      const nodeMap = graph.nodes.reduce((a, node) => {
        a[node.id] = node;
        return a;
      }, {});
      const node = nodeMap[selectedNodeId];
      if (node) {
        const properties = Object.entries(node.data || {})
          .filter(([k, v]) => k !== 'id' && typeof v !== 'undefined')
          .map(([k, v]) => {
            if (typeof v === 'boolean') {
              return [k, v ? 'true' : 'false'];
            }
            return [k, v];
          });
        properties.sort((a, b) => a[0] < b[0] ? -1 : 1);
        const nodeAsSourceEdges = [];
        const nodeAsTargetEdges = [];
        for (const edge of graph.edges) {
          if (edge.source === node.id) {
            const targetNode = nodeMap[edge.target];
            nodeAsSourceEdges.push(({
              type: edge.label,
              id: edge.target,
              target: targetNode.type,
              name: targetNode.label,
            }));
          }
          if (edge.target === node.id) {
            const sourceNode = nodeMap[edge.source];
            nodeAsTargetEdges.push({
              type: edge.label,
              id: edge.source,
              source: sourceNode.type,
              name: sourceNode.label,
            });
          }
        }
        return {
          id: node.id,
          type: node.type,
          label: node.label,
          properties,
          nodeAsSourceEdges,
          nodeAsTargetEdges,
        };
      }
    }
    return null;
  }, [selectedNodeId]);

  return (
    <>
      <AddNodeInstanceModal
        onCancel={handleAddNodeInstanceModalCancel}
        onSubmit={handleAddNodeInstanceSubmit}
        open={addNodeInstanceModalOpen}
        schema={nodesMetadata}
        sourceType={selectedLabel}
      />
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Segmented
            onChange={(value) => onChange({ fetchType: value })}
            value={value.fetchType}
            size="small"
            style={{ background: 'rgba(0, 0, 0, 0.25)' }}
            defaultValue={'search'}
            options={[
              {
                label: 'Search',
                value: 'search',
              },
              {
                label: 'Query Builder',
                value: 'query',
              },
              {
                label: 'Cypher Query',
                value: 'cypher',
              },
            ]}
          />
          <div style={{ flex: 1 }}></div>
          <Segmented
            onChange={(value) => onChange({ layout: value })}
            value={value.layout}
            size="small"
            style={{ background: 'rgba(0, 0, 0, 0.25)' }}
            options={[
              {
                label: 'Graph',
                value: 'graph',
              },
              {
                label: 'Table',
                value: 'table',
              },
            ]}
          />
        </div>
        <div style={{ display: 'flex', gap: 8, paddingTop: 16 }}>
          <div style={{ minHeight: 88 }}>
            {value.fetchType === 'search' ?
              <Search allowClear
                value={searchText}
                onChange={handleSearchChange}
                onSearch={(value) => onChange({ searchQuery: value })}
                placeholder="Enter natural language query..."
                style={{ width: 500 }}
              />
              : null
            }
            {value.fetchType === 'query' && !isEmpty(nodesMetadata) ?
              <CypherQueryBuilder
                nodesMetadata={nodesMetadata}
                relationshipsMetadata={relationshipsMetadata}
                onQuery={(value) => onChange({ query: value })}
                state={value.queryBuilderState}
              />
              : null
            }
            {value.fetchType === 'cypher' ?
              <Space direction="vertical" size={[0, 8]}>
                <TextArea
                  ref={cypherInput}
                  autoSize={{ minRows: 1, maxRows: 14 }}
                  placeholder="Enter cypher query..."
                  style={{ borderRadius: 16, width: 500 }}
                />
                <Button
                  size="small"
                  type="primary"
                  onClick={() => onChange({ cypherQuery: cypherInput.current.resizableTextArea.textArea.value })}
                >
                  Query
                </Button>
              </Space>
              : null
            }
          </div>
          <div style={{ flex: 1 }}></div>
          {value.layout === 'graph' ?
            <>
              <div>
                <Button
                  type="primary"
                  onClick={() => handleAddNodeInstance({ data: { type: null } })}
                >
                  Add Node
                </Button>
              </div>
              <div>
                <Segmented
                  onChange={(value) => onChange({ nodeStyle: value })}
                  value={value.nodeStyle}
                  style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                  options={[
                    {
                      label: 'Color',
                      value: 'color',
                    },
                    {
                      label: 'Icon',
                      value: 'icon',
                    },
                  ]}
                />
              </div>
            </>
            :
            <div>
              <Segmented
                onChange={(value) => onChange({ tableView: value })}
                value={value.tableView}
                style={{ background: 'rgba(0, 0, 0, 0.25)' }}
                options={[
                  {
                    label: 'Nodes',
                    value: 'nodes',
                  },
                  {
                    label: 'Relationships',
                    value: 'edges',
                  },
                ]}
              />
            </div>
          }
        </div>
        {value.layout === 'graph' ?
          <>
            <div style={{ flex: 1, position: 'relative', width: '100%', minWidth: 500 }}>
              <div className="graph-legend">
                <fieldset>
                  <legend>Legend</legend>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <label></label>
                    <div className="color-circle" style={{ backgroundColor: '#FBFBFB' }} />
                    <Checkbox
                      checked={selectAllState === 1}
                      indeterminate={selectAllState == 0}
                      onChange={handleSelectAllChange}
                    />
                  </div>
                  {value.legend?.map(([label, color, icon]) => (
                    <div key={label} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <label>{label}</label>
                      {value.nodeStyle === 'icon' && icon ?
                        <div className="color-circle">{icons[icon].icon}</div>
                        :
                        <div className="color-circle" style={{ backgroundColor: color }} />
                      }
                      <Checkbox
                        checked={value.selectedTypes?.[label]}
                        onChange={(ev) => handleTypeSelection(label, ev.target.checked)}
                      />
                    </div>
                  ))}
                </fieldset>
              </div>
              {value.graph?.nodes?.length ?
                <GraphCanvas draggable
                  {...value.graph}
                  ref={graphRef}
                  selections={selections}
                  actives={actives}
                  onCanvasClick={handleCanvasClick}
                  onNodeClick={onNodeClick}
                  onNodeDoubleClick={handleNodeDoubleClick}
                  theme={theme}
                  layoutOverrides={{
                    getNodePosition: id => {
                      return nodeRef.current.get(id)?.position;
                    }
                  }}
                  onNodeDragged={node => {
                    nodeRef.current.set(node.id, node);
                  }}
                  contextMenu={({ data, onClose }) => (
                    <RadialMenu
                      onClose={onClose}
                      items={[
                        {
                          label: 'Add',
                          onClick: () => {
                            handleAddNodeInstance(data);
                            onClose();
                          }
                        },
                        {
                          label: 'Expand',
                          onClick: () => {
                            onClose();
                          }
                        },
                        {
                          label: 'Delete',
                          onClick: () => {
                            handleDeleteNodeInstance(data);
                            onClose();
                          }
                        },
                        {
                          label: 'Contract',
                          onClick: () => {
                            onClose();
                          }
                        },
                      ]}
                    />
                  )}
                />
                : null
              }
            </div>
            <div style={{ height: 24, paddingTop: 8 }}>
              <Pagination
                {...value.pageOptions}
                onChange={(page, pageSize) => onChange({ page, pageSize })}
                size="small"
                pageSizeOptions={[100, 1000, 10000]}
                defaultPageSize={1000}
              />
            </div>
          </>
          : null
        }
        {value.layout === 'table' && value.dataSource ?
          <div style={{ height: 'calc(100vh - 168px)', overflow: 'auto' }}>
            <EditableTable
              dataSource={value.dataSource}
              defaultColumns={value.defaultColumns}
              tableType={value.tableType}
              setTableType={(value) => onChange({ tableType: value })}
              tableView={value.tableView}
              onChangeTable={(value) => onChange({ tableParams: value })}
              onAddColumn={onAddColumn}
              onSaveProperty={onSaveProperty}
              // onAdd={handleAdd}
              // onDelete={handleDelete}
              onSave={handleSave}
              nodeOptions={nodeOptions}
              onQuery={({ filters, params }) => onChange({ filters, params })}
            />
          </div>
          : null
        }
      </div>
      <Drawer
        closable={true}
        onClose={onDrawerClose}
        open={!!selectedNodeId}
        placement="right"
        title="Properties"
        width={500}
      >
        <>
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item key={'__id'}
              label={'id'}
              labelStyle={{ width: 130 }}
            >
              {selectedNode?.id}
            </Descriptions.Item>
            <Descriptions.Item key={'__type'}
              label={'type'}
              labelStyle={{ width: 130 }}
            >
              {selectedNode?.type}
            </Descriptions.Item>
            <Descriptions.Item key={'__label'}
              label={'label'}
              labelStyle={{ width: 130 }}
              style={{ borderBottom: '2px solid #ccc' }}
            >
              {selectedNode?.label}
            </Descriptions.Item>
            {selectedNode?.properties.map(([key, val]) =>
              <Descriptions.Item key={key}
                label={lowerCase(key)}
                labelStyle={{ width: 130 }}
              >
                {Array.isArray(val) ? val.join(', ') : val}
                {key === 'video_id' && val ?
                  <div style={{ marginTop: 8 }}>
                    <YouTube
                      onReady={onReady}
                      opts={opts}
                      videoId={val}
                    />
                  </div>
                  : null
                }
              </Descriptions.Item>
            )}
          </Descriptions>
          {selectedNode?.nodeAsTargetEdges?.length ?
            <>
              <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                Incoming relationships
              </div>
              <Descriptions bordered column={1} size="small">
                {selectedNode?.nodeAsTargetEdges?.map((rel) =>
                  Object.entries(rel).map(([key, val], i) =>
                    <Descriptions.Item key={key}
                      label={lowerCase(key)}
                      labelStyle={{ width: 130 }}
                      style={{ borderBottom: i === (Object.keys(rel).length - 1) ? '2px solid #ccc' : 'inherit' }}
                    >
                      {val}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </>
            : null
          }
          {selectedNode?.nodeAsSourceEdges?.length ?
            <>
              <div style={{ fontWeight: 400, marginBottom: 5, marginTop: 16 }}>
                Outgoing relationships
              </div>
              <Descriptions bordered column={1} size="small">
                {selectedNode?.nodeAsSourceEdges?.map((rel) =>
                  Object.entries(rel).map(([key, val], i) =>
                    <Descriptions.Item key={key}
                      label={lowerCase(key)}
                      labelStyle={{ width: 130 }}
                      style={{ borderBottom: i === Object.keys(rel).length - 1 ? '2px solid #ccc' : 'inherit' }}
                    >
                      {val}
                    </Descriptions.Item>
                  )
                )}
              </Descriptions>
            </>
            : null
          }
        </>
      </Drawer>
    </>
  );
}